<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$cbglKzjlSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :noOperation="true" :isKzjl="true" :dynamic="true"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @tableColumnClick="tableColumnClick"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          queryType: '0',
          queryKey: 'ConcentratorAddress',
          queryValue: '',
          otherKey:'',
          otherValue:'',
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          belongArea:'',
          areaName:'',
          areaAddress:'',
          areaNum:'',
          equipment:'false'
        },
        FormList:{//表格列
          column:[
            {columnKey:'belongArea',columnDescription:'归属区域',columnType:'text',prop:'belongArea',width:'120',placeholder:"归属区域",},
            {columnKey:'areaName',columnDescription:'区域名称',columnType:'text',prop:'areaName',width:'120',placeholder:"区域名称",},
            {columnKey:'areaAddress',columnDescription:'区域地址',columnType:'text',prop:'areaAddress',width:'120',placeholder:"区域地址",},
            {columnKey:'areaNum',columnDescription:'区域负责人编号',columnType:'text',prop:'areaNum',width:'120',placeholder:"区域负责人编号",},
            {
              columnKey:'equipment',
              columnDescription:'挂载设备',
              columnType:'select',
              placeholder:"请选择挂载设备",
              prop:'equipment',
              width:'120',
              seleceOption:[
                {label:'否',value:'0'},
                {label:'是',value:'1'},
              ]
            },
          ],
          //校验
          rule:{
            belongArea: [{ required: true, message: "归属区域不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      this.getList()
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.kzjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        if(this.listQuery.otherKey == 'ApplyTime' || this.listQuery.otherKey == 'ControlTime'){
          if(this.listQuery.otherValue.length >0){
            this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.otherValue[0],'yyyy-MM-dd');
            this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.otherValue[1],'yyyy-MM-dd');
            this.listQuery.otherValue=[this.listQuery.beginTime,this.listQuery.endTime]
          }
        }
        // var s = JSON.stringify(this.listQuery)
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          name:'',
          type:'plbz',
          uploadFilsName:'',//上传文件名
        }
        this.FormDialog = false
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
